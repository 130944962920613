body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.uppy-DragDrop--is-dragdrop-supported {
    border: 2px dashed !important;
    border-color: #adadad !important;
}
.uppy-DragDrop-inner {
    padding: 20px 20px !important;
    width: 100;
}
.uppy-StatusBar:not(.is-waiting) {
    margin-top: 10px;
}
.uppy-Root {
    margin-top: 15px !important;
}
.uploaded-image {
    border-radius: 5px;
    cursor: pointer;
}
.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.fc-button {
    color: #fff;
    background-color: #f57c00 !important;
    border-color: #f57c00 !important;
}
.fc-button:hover {
    background-color: #f57c00 !important;
}
.fc-left > h2 {
    font-size: 1.25em !important;
}
.fc-event-dot {
    background-color: #f57c00 !important;
}
.fc-event {
    cursor: pointer;
}
.error {
    color: red;
}
